import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translator/en.json";
import ge from "./translator/ge.json";

// ***** start - resources for translation file *****
const resources = {
  en: {
    translation: en,
  },
  ge: {
    translation: ge,
  },
};
// ***** end - resources for translation file *****

// ***** start - config. of translation file *****
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("langcode"),
  // fallbackLng: "ge",
  fallbackLng: process.env.REACT_APP_LANGUAGE,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});
// ***** end - config. of translation file *****

export default i18n;
