// ** Logo
import logo from "@src/assets/images/logo/logo.png";
import "./loader.scss";
const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <svg
        width="70"
        height="37"
        viewBox="0 0 70 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_620_3714)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.5629 0.406925C30.8017 0.442315 30.4435 0.468211 29.939 0.519139C27.8277 0.743732 25.761 1.2795 23.8058 2.10912C22.0673 2.85261 20.4388 3.83167 18.9658 5.01892C18.4905 5.40217 0.608398 20.1246 0.608398 20.135C0.608398 20.1453 9.19816 29.3037 9.21883 29.3037C9.35158 29.2133 9.47815 29.1141 9.59769 29.0068C10.0282 28.6555 10.1565 28.5553 10.4036 28.3844C12.5209 26.917 15.2065 26.3991 17.7888 26.9559C19.0027 27.219 20.153 27.7197 21.1736 28.4293L21.3027 28.5208L21.8279 28.0892C22.119 27.8519 23.1849 26.9714 24.2001 26.1358L26.0453 24.6166L25.9765 24.5303C25.5905 24.0414 25.249 23.5188 24.9561 22.9688C24.2643 21.6835 23.8598 20.2626 23.7705 18.8048C23.7496 18.2897 23.7577 17.7739 23.7946 17.2597C24.0658 14.6382 25.2489 12.3396 27.2499 10.5562C29.6256 8.43453 32.8639 7.39267 36.1316 7.70773C38.1171 7.89152 40.0243 8.57435 41.6767 9.69306L41.8489 9.80959L44.1324 7.80786C45.3904 6.70817 46.4159 5.8001 46.4228 5.7906C46.4297 5.78111 46.0267 5.41943 45.6478 5.11041C42.9037 2.85837 39.5275 1.33571 35.9181 0.720261C35.0863 0.578649 34.2471 0.484697 33.4047 0.438863C33.1386 0.415557 31.7894 0.400019 31.5629 0.406925Z"
            fill="#8C8CF9"
            className="svg-elem-1"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.5428 7.47036C60.1562 7.78888 59.9624 7.93734 59.6895 8.12811C58.4314 8.9877 56.9832 9.52666 55.4704 9.69824C55.0448 9.7503 54.6159 9.76963 54.1874 9.75607C53.7895 9.75626 53.3921 9.72741 52.9983 9.66975C51.5531 9.46356 50.1748 8.92628 48.9703 8.09962C48.8722 8.02971 48.8059 7.99173 48.793 7.99949C48.78 8.00726 44.1261 11.832 44.0856 11.8726C44.071 11.8847 44.1141 11.9511 44.2518 12.1316C45.4858 13.7402 46.2139 15.6808 46.3433 17.706C46.3648 18.2208 46.3562 18.7364 46.3175 19.2502C46.2044 20.4229 45.8879 21.5669 45.3824 22.6304C44.1459 25.22 41.8065 27.2571 38.9487 28.2291C37.649 28.6688 36.2835 28.8805 34.9121 28.8549C34.5148 28.8506 34.1181 28.8238 33.7238 28.7746C31.8198 28.5586 29.9966 27.882 28.4112 26.8031C28.3388 26.7495 28.2625 26.7013 28.183 26.6589C28.1624 26.6589 23.5713 30.5959 23.5713 30.614C23.5713 30.6322 24.2386 31.226 24.6622 31.5635C27.8584 34.1108 31.8089 35.6637 35.9901 36.021C36.6134 36.0794 37.2396 36.1025 37.8655 36.0901C38.4911 36.1025 39.1169 36.0794 39.74 36.021C42.757 35.7674 45.6852 34.871 48.3289 33.3918C49.2525 32.8706 50.1353 32.28 50.9697 31.6248C51.3546 31.3201 69.4916 16.3758 69.4916 16.3655C69.4916 16.3551 60.9053 7.18896 60.8923 7.18896C60.8794 7.18896 60.7322 7.31758 60.5479 7.47295L60.5428 7.47036Z"
            fill="#8C8CF9"
            className="svg-elem-2"
          ></path>
          <path
            d="M34.9522 24.8635C38.8435 24.8635 41.9981 21.9063 41.9981 18.2584C41.9981 14.6105 38.8435 11.6533 34.9522 11.6533C31.0608 11.6533 27.9062 14.6105 27.9062 18.2584C27.9062 21.9063 31.0608 24.8635 34.9522 24.8635Z"
            fill="black"
            className="svg-elem-3"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_620_3714">
            <rect
              width="68.8834"
              height="35.6866"
              fill="white"
              transform="translate(0.608398 0.406738)"
              className="svg-elem-4"
            ></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SpinnerComponent;
